const CheckEmailIcon = () => {
  return (
    <svg
      width="50"
      height="42"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 9V1.5H1V16.5H11M21 14H14M21 14L18.5 11.5M21 14L18.5 16.5"
        stroke="#4f46e5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1.5L11 9L21 1.5"
        stroke="#4f46e5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckEmailIcon;
